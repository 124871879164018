import React, { useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { getMenuPage } from "../services/getMenuPage";
import { getPageContent } from "../services/getPageContent";
import { ImageLink } from "../constants";
import { toast } from "react-toastify";

const NewPage = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const navigator = useNavigate();
  const [contentData, setContentData] = useState([]);
  const [menuPageData, setMenuPageData] = useState(null);
  useMemo(() => {
    const fetchData = async () => {
      try {
        const menuResponse = await getMenuPage(pathname);
        setMenuPageData(menuResponse.document);
        if (menuResponse.document === "data not found") {
          navigator("/")
        }
        if (menuResponse.document.CMS && menuResponse.document.CMS._id) {
          const contentResponse = await getPageContent(
            menuResponse.document.CMS._id
          );
          setContentData(contentResponse.data);
        }
      } catch (error) {
        toast.error(error.response.data.error);
      }
    };

    fetchData();
  }, [pathname, navigator]);

  return (
    <>
      {menuPageData?.CMS?.title && (
        <Helmet>
          <title>{`K M Choksi - ${menuPageData?.CMS?.title}`}</title>
        </Helmet>
      )}
      <main id="content">
        <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${ImageLink(
              menuPageData?.CMS?.background_image
            )})`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <div className="inner-banner-text">
                  <h1>{menuPageData?.CMS?.title}</h1>
                  <p>{menuPageData?.CMS?.subTitle}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <img
                  className="w-100 inner-banner-img"
                  src={ImageLink(menuPageData?.CMS?.main_banner_image)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 pt-md-15 pb-md-15">
          <div className="container container-xxl">
            {contentData.contentId?.map((data, i) => {
              return (
                <div
                  key={i}
                  dangerouslySetInnerHTML={{ __html: data.description }}
                />
              );
            })}
          </div>
        </section>
      </main>
    </>
  );
};

export default NewPage;
