import React from 'react'
import ProductView from './ProductView'
import RandomProduct from './RandomProduct'

const ProductDetails = ({contact}) => {  
  return (
    <div>
      <ProductView contact={contact}/>
      <RandomProduct />
    </div>
  )
}

export default ProductDetails