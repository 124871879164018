import React, { useState, useMemo } from "react";
import Slider from "react-slick";
import { getAllBrand } from "../services/getAllBrand";
import { Link } from "react-router-dom";
import { Animated } from "react-animated-css";
import { ImageLink } from "../constants";
import { toast } from "react-toastify";

const Section5 = ({ menu, settingData }) => {
  const [brand, setBrand] = useState([]);
  const settings1 = {
    slidesToShow: 6,
    infinite: false,
    autoplay: false,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          arrows: true
        },
      },
    ],
  };

  useMemo(async () => {
    try {
      const [brandRes] = await Promise.all([getAllBrand()]);
      setBrand(brandRes.document);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }, []);
const filterData =brand.filter((item)=> item.showInHome === true)
  return (
    <>
      {brand.length > 0 && (
        <>
          <section className="py-8 border-bottom">
            <div className="container">
              {brand.length < 6 ? (
                <div className="d-none d-md-flex justify-content-center ">
                  {brand.map(
                    (data, i) =>
                      data.isActive && (
                        <Link to={`/${menu[1]?.menuUrl}/${data.slug}`} key={i}>
                          <div className="box p-2 hover-shine hover-change-content cursor-pointer">
                            <img
                              src={ImageLink(data.logo_image)}
                              alt="Brand Logo"
                              className="brand-nav-img"
                              // width={150}
                              // height={100}
                            />
                          </div>
                        </Link>
                      )
                  )}
                </div>
              ) : (
                <div className={`${brand.length < 6 ? "d-md-none" : ""}`}>
                  <Slider {...settings1}>
                    {brand.map(
                      (data, i) =>
                        data.isActive && (
                          <Link to={`/${menu[1]?.menuUrl}/${data.slug}`} key={i}>
                            <div className="box hover-shine hover-change-content cursor-pointer">
                              <img
                                src={ImageLink(data.logo_image)}
                                alt="Brand Logo"
                                className="brand-nav-img"                   
                              />
                            </div>
                          </Link>
                        )
                    )}
                  </Slider>
                </div>
              )}
            </div>
          </section>

         {filterData === true && <section className="py-8">
            <div className="container-fluid">
              {brand.map(
                (data, index) =>
                  data.isActive &&
                  data.showInHome && (
                    <div
                      className={`row ${index % 2 === 0 ? "" : "pt-8 flex-md-row-reverse"
                        }`}
                      key={index}
                    >
                      <Animated
                        animationOut="fadeInLeft"
                        animationIn="fadeInLeft"
                        isVisible={true}
                        className="col-lg-6 text-center brand-bg mb-5 hover-shine hover-change-content"
                      >
                        <Link to={`/${menu[1]?.menuUrl}/${data.slug}`}>
                          <img                       
                            src={ImageLink(data.collection_image)}
                            alt="Brand"                            
                          />
                        </Link>
                      </Animated>
                      <Animated
                        animationOut="fadeInRight"
                        animationIn="fadeInRight"
                        isVisible={true}
                        className={`col-lg-6 ${index % 2 === 0 ? "text-left" : "text-right"
                          }`}
                      >
                        <div className="pl-sm-10 pl-sm-0 d-flex flex-column justify-content-center align-i-center h-100">
                          <Link to={`/${menu[1]?.menuUrl}/${data.slug}`}>
                            <h2 className="fs-34 mb-0 hover-name">
                              {data.title}
                            </h2>
                          </Link>
                          <h3 className="fs-24 mb-3">{data.subTitle}</h3>
                          <p className="mb-7 font-weight-500">
                            {data.description}
                          </p>
                          <div>
                            <Link
                              to={`/${menu[1]?.menuUrl}/${data.slug}`}
                              className="btn btn-outline-primary text-uppercase letter-spacing-05"
                            >
                              EXPLORE THE COLLECTION
                            </Link>
                          </div>
                        </div>
                      </Animated>
                    </div>
                  )
              )}
            </div>
          </section>}

          {settingData.map((item, i) => {
            return (
              item.showNotificationBanner === true && (
                <section key={i} className="py-8">
                  <div className="container">
                    <img                      
                      src={ImageLink(item.notificationBanner)}
                      alt="Offer"                      
                    />
                  </div>
                </section>
              )
            );
          })}
        </>
      )}
    </>
  );
};

export default Section5;
