import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleScrollToTop = (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            zIndex: 10,
            display: isVisible ? 'block' : 'none'
        }}>
            <Link to="#" onClick={handleScrollToTop} className="scroll-to-top" title="Back To Top">
                <i className="fas fa-arrow-up"></i>
            </Link>
        </div>
    );
}

export default ScrollToTop;
