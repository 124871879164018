import React, { useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import axiosInstance from "../axiosInterceptor";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getMenuPage } from "../services/getMenuPage";
import { ImageLink } from "../constants";

const Careers = () => {
  const { register, handleSubmit, reset } = useForm({});
  const [isLoading, setIsLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(true);
  const location = useLocation();
  const pathname = location.pathname;
  const [menuPageData, setMenuPageData] = useState(null);

  const fetchMenuPage = useCallback(async () => {
    if (pathname) {
      const response = await getMenuPage(pathname);
      setMenuPageData(response.document);
      setIsLoading(false);
    }
  }, [pathname]);

  useEffect(() => {
    fetchMenuPage();
  }, [fetchMenuPage]);

  const handlePhoneChange = (value, e) => {
    const formattedPhone = value.startsWith("+") ? value : `+${value}`;

    setPhone(formattedPhone);
    setIsValidPhone(e?.target?.valid || false);
  };

  const onSubmit = async (data) => {
    if (!isValidPhone) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    try {
      const response = await axiosInstance.post(`api/contactinquary`, {
        name: data.name,
        email: data.email,
        mobile_no: phone,
        message: data.message,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        reset();
      }
      setIsLoading(false);
    } catch (error) {
      toast.error(error.response?.data?.originalError || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {menuPageData?.CMS?.title && (
        <Helmet>
          <title>{`K M Choksi - ${menuPageData?.CMS?.title}`}</title>
        </Helmet>
      )}
      <main id="content">
        <section
          className="inner-banner"
          style={{
            backgroundImage: `url(${ImageLink(
              menuPageData?.CMS?.background_image
            )})`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3">
                <div className="inner-banner-text">
                  <h1>{menuPageData?.CMS?.title}</h1>
                  <p>{menuPageData?.CMS?.subTitle}</p>
                </div>
              </div>
              <div className="col-lg-9">
                <img                
                  className="w-100 inner-banner-img"
                  src={ImageLink(menuPageData?.CMS?.main_banner_image)}
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>

        <section className="pb-12 pt-lg-10">
          <div className="container">
            <div className="row">
              <div className="col-md-10 mx-auto">
                <h2 className="fs-24 mb-2 font-weight-bold">
                  We would love for you to join us.
                </h2>
                <p className="mb-7">
                  If you're passionate about your work and interested in joining
                  our team, reach out to us.
                </p>
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                  <div className="row mb-6">
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name*"
                        name="name"
                        {...register("name")}
                        required
                      />
                    </div>
                  </div>
                  <div className="row mb-6">
                    <div className="col-sm-6">
                      <input
                        type="email"
                        className="form-control mobile-view"
                        placeholder="Your Email*"
                        name="email"
                        id="email"
                        {...register("email")}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={handlePhoneChange}
                        inputClass="form-control"
                      />
                    </div>
                  </div>

                  <div className="row mb-6">
                    <div className="col-sm-6">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Post*"
                        name="post"
                        id="post"
                        {...register("post")}
                        required
                      />
                    </div>

                    <div className="col-sm-6">
                      <input
                        type="file"
                        className="form-control pdf-upload"
                        placeholder="Post*"
                        name="post"
                        id="post"
                        {...register("post")}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mb-4">
                    <textarea
                      type="text"
                      placeholder="Message*"
                      className="form-control"
                      rows={6}
                      name="message"
                      {...register("message")}
                      required
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn btn-primary text-uppercase letter-spacing-05"
                      disabled={isLoading}
                    >
                      {isLoading ? "Submitting..." : "Apply Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Careers;
