import React from "react";
import Section1 from "../components/Section1";
import { Helmet } from "react-helmet";

const Home = ({ menu, logo, contact, socialData }) => {
  return (
    <>
      <Helmet>
        <title>{"K M Choksi"}</title>
      </Helmet>
      <main id="content">
        <Section1
          menu={menu}
          logo={logo}
          contact={contact}
          socialData={socialData}
        />
      </main>
    </>
  );
};

export default Home;
