import React, { useEffect, useState } from 'react'
import { getRandomProduct } from '../services/getRandomProduct';
import { ImageLink } from '../constants';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

const RandomProduct = () => {
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    const navigator = useNavigate();
    const [randomProduct, setRandomProduct] = useState([]);

    useEffect(() => {
        (async function () {
            const res = await getRandomProduct();
            setRandomProduct(res.document);
        })();
    }, []);
    return (
        <>
            <section className="pb-11 pb-lg-15">
                <div className="container container-xxl">
                    <div className="d-flex justify-content-center">
                        <h2 className="fs-28 mb-5 text-center border-buttom">You may also like.. </h2>
                    </div>
                    <div className=''>
                        <Slider {...settings}>
                            {
                                randomProduct?.map((product, index) => {
                                    return (
                                        <div className="mt-6" key={index}>
                                            {product.isActive === true ?
                                                <div className="">                                                   
                                                    <div className="card border-0 hover-change-content product">
                                                        <div className="card-img-top position-relative"
                                                            onClick={() => { navigator(`/randomproduct/${product.slug}`, { state: product }) }}
                                                        >
                                                            <div
                                                                style={{ backgroundImage: `url(${ImageLink(product.mediumproduct_image[0])})` }}
                                                                className="card-img ratio bg-img-cover-center ratio-1-1"
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                </div>
            </section >
        </>
    )
}

export default RandomProduct