import React, { useState, useCallback, useEffect } from "react";
import { Animated } from "react-animated-css";
import { getAllBrand } from "../services/getAllBrand";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getMenuPage } from "../services/getMenuPage";
import { ImageLink } from "../constants";
import Loader from "../layout/Loader";
import { toast } from "react-toastify";

const OurBrands = () => {
  const [brand, setBrand] = useState([]);
  const [menuPageData, setMenuPageData] = useState([]);
  const location = useLocation();
  const pathname = location.pathname;
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const [menuPageResponse, brandResponse] = await Promise.all([
        getMenuPage(pathname),
        getAllBrand(),
      ]);
      setMenuPageData(menuPageResponse.document);
      setBrand(brandResponse.document);
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  }, [pathname]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {menuPageData?.CMS?.title && (
        <Helmet>
          <title>{`K M Choksi - ${menuPageData?.CMS?.title}`}</title>
        </Helmet>
      )}
      {menuPageData?.CMS?.background_image &&
        <main id="content">
          {menuPageData?.CMS?.background_image ?
            <section
              className="inner-banner"              
              style={{
                backgroundImage: `url(${ImageLink(
                  menuPageData?.CMS?.background_image
                )})`,
              }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="inner-banner-text">
                      <h1>{menuPageData?.CMS?.title}</h1>
                      <p>{menuPageData?.CMS?.subTitle}</p>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <img                      
                      className="w-100 inner-banner-img"
                      src={ImageLink(menuPageData?.CMS?.main_banner_image)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section> :
            <section className="inner-banner" style={{
              backgroundImage: `url(../images/4.jpg)`,
              height: '308px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }} />}

          <section className="pt-10 pb-12">
            <div className="container-fluid">
              {brand &&
                brand.map((data, index) => {
                  return data.isActive === true ? (
                    <div
                      className={`row ${index % 2 === 0 ? "" : "flex-md-row-reverse"
                        }`}
                      key={index}
                    >
                      <Animated
                        animationOut="fadeInLeft"
                        animationIn="fadeInLeft"
                        isVisible={true}
                        className="col-lg-6 text-center brand-bg mb-5 hover-shine hover-change-content cursor-pointer"
                      >
                        <img
                          className="11"                          
                          src={ImageLink(data.collection_image)}
                          alt=""
                          onClick={() =>
                            navigator(`${pathname}/${data.slug}`, { state: data })
                          }
                        />
                      </Animated>
                      <Animated
                        animationOut="fadeInRight"
                        animationIn="fadeInRight"
                        isVisible={true}
                        className={`col-lg-6 ${index % 2 === 0 ? "text-left" : "text-right"
                          }`}
                      >
                        <div
                          className="pl-sm-0 d-flex flex-column justify-content-center align-i-center h-100"
                        >
                          <div className="brand-logo text-end hover-shine hover-change-content cursor-pointer">
                            <img
                              className="brand-logo-img radius-logo"
                              src={ImageLink(data.logo_image)}
                              alt="brand-logo"                              
                              onClick={() =>
                                navigator(`${pathname}/${data.slug}`, { state: data })
                              }
                            />
                          </div>

                          <h2 className="fs-34 mb-0 hover-name cursor-pointer">
                            {data.title}
                          </h2>
                          <h3 className="fs-24 mb-3 text-uppercase">
                            {data.subTitle}
                          </h3>
                          <p className="mb-7 font-weight-500">
                            {data.description}
                          </p>
                          <div className="mb-5">
                            <span className="btn btn-outline-primary text-uppercase letter-spacing-05"
                              onClick={() =>
                                navigator(`${pathname}/${data.slug}`, { state: data })
                              }>
                              EXPLORE THE COLLECTION
                            </span>
                          </div>
                        </div>
                      </Animated>
                    </div>
                  ) : (
                    ""
                  );
                })}
            </div>
          </section>
        </main>}
    </>
  );
};

export default OurBrands;
