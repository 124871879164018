import React from "react";

const Section9 = () => {
  return (
    <>
      <section className="instagram-bg">
        <div className="container container-xxl ">
          <div className="row ">
            <iframe
              src="https://embedsocial.com/api/pro_hashtag/6fa301809959308f24d07c24565cdb1ecebbf06a"
              width="100%"
              height="600px"
              title="Embedded Social Media Feed"
              style={{ border: "none" }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Section9;
