import React, { useState, useMemo, useEffect } from "react";
import Slider from "react-slick";
import { getTestimonial } from "../services/getTestimonial";
import { ImageLink } from "../constants";
import { toast } from "react-toastify";

const Section8 = ({ settingData }) => {
  const settings2 = {
    slidesToShow: 3,
    infinite: true,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [testimonial, setTestimonial] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const [testimonialRes] = await Promise.all([getTestimonial()]);
        setTestimonial(testimonialRes.document);
      } catch (error) {
        toast.error("Error fetching testimonials:", error);
      }
    })();
  }, []);

  const activeTestimonials = useMemo(
    () => testimonial.filter((data) => data.isActive),
    [testimonial]
  );

  return (
    <>
      {activeTestimonials.length > 0 && (
        <section className="pb-8 pt-2">
          <div className="container cursor-pointer">
            <h2 className="fs-30 mb-8 text-center">
              {settingData[0]?.testimonial_title}
            </h2>
            {activeTestimonials.length < 3 ? (activeTestimonials.map((data, i) =>
              data.isActive && (
                <div className="box" key={data._id}>
                  <div className="card border-0 shadow-sm rounded-lg bg-light">
                    <div className="card-body text-center testimonial-body">
                      <p className="card-text mb-4 font-weight-500">
                        {data.message}
                      </p>
                      <p className="card-text text-primary font-weight-bold mb-1 fs-17">
                        {data.name}
                      </p>
                      <div className="mx-auto mb-4">
                        <img
                          src={ImageLink(data.testimonial_image)}
                          alt={data.name}
                          className="img-fluid testimonial-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            )
            ) : (
              <div className={`${activeTestimonials.length < 3 ? "d-md-none" : ""}`}>
                <Slider {...settings2}>
                  {activeTestimonials.map(
                    (data, i) =>
                      data.isActive && (
                        <div className="testimonial-box" key={data._id}>
                          <div className="testimonial-card border-0 shadow-sm rounded-lg bg-light">
                            <div className="card-body text-center testimonial-body">
                              <p className="card-text mb-4 font-weight-500 fs-14">
                                {data.message}
                              </p>
                              <p className="card-text text-primary font-weight-bold mb-1 fs-17">
                                {data.name}
                              </p>
                              <div className="mx-auto mb-4">
                                <img
                                  src={ImageLink(data.testimonial_image)}
                                  alt={data.name}
                                  className="img-fluid testimonial-img mt-5"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </Slider>
              </div>
            )}
          </div>
        </section >
      )}
    </>
  );
};

export default Section8;