import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './layout/ScrollToTop';
import Scroll from './layout/Scroll';
import Footer from './layout/Footer';
import { getAllCms } from './services/getAllCms';
import { useEffect, useState, Suspense } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './layout/Header';
import { getWebLogo } from './services/getWebLogo';
import { getContact } from './services/getContact';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getSocialMedia } from './services/getSocialMedia';
import AppRoutes from './AppRoutes';

function App() {
  const [menu, setMenu] = useState([]);
  const [logo, setLogo] = useState([]);
  const [contact, setContact] = useState([]);
  const [socialData, setSocialData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [menuRes, logoRes, contactRes, socialRes] = await Promise.all([
          getAllCms(),
          getWebLogo(),
          getContact(),
          getSocialMedia()
        ]);
        setMenu(menuRes.document);
        setLogo(logoRes.document);
        setContact(contactRes.document);
        setSocialData(socialRes.document);
      } catch (error) {
        toast.error(error.response.data.error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Router>
        <Suspense >
          <Header menu={menu} logo={logo} socialData={socialData} />
          <ScrollToTop />
          <Scroll />
          <AppRoutes menu={menu} contact={contact} />
          {logo.length > 0 && <Footer menu={menu} logo={logo} contact={contact} socialData={socialData} />}
        </Suspense>
        <ToastContainer autoClose={1000} theme="colored" />
      </Router>
    </>
  );
}

export default App;