import React, { useMemo, useState } from 'react';
import Slider from 'react-slick';
import { getHomeSlider } from '../services/getHomeSlider';
import { ImageLink } from '../constants';
import { getSetting } from '../services/getSetting';
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section7 from './Section7'
import Section8 from './Section8'
import Section9 from './Section9'
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';

const Section1 = ({ menu }) => {
  const [homeSlider, setHomeSlider] = useState([]);
  const isMobile = window.innerWidth <= 768;
  const [isLoading, setIsLoading] = useState(false)
  useMemo(() => {
    const fetchHomeSlider = async () => {
      try {
        setIsLoading(true)
        const [homeSliderRes] = await Promise.all([getHomeSlider()]);
        setHomeSlider(homeSliderRes.document);
        setIsLoading(false)
      } catch (error) {
        toast.error(error.response.data.error);
      }
    };

    fetchHomeSlider();
  }, []);

  const [settingData, setSettingData] = useState([])
  const fetchSettingData = async () => {
    const res = await getSetting();
    setSettingData(res.document)
  };

  useMemo(() => {
    fetchSettingData();
  }, []);

  const sliderSettings = {
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    dots: true,
    arrows: false,
  };

  const activeSliders = homeSlider.filter(data => data.isActive);

  if (isLoading) {
    return <div className="pre-loader">
      <Loader />
    </div>
  }
  return (
    <>
      {homeSlider.length > 0 && (
        <>
          <section>
            <Slider
              className="main-slider slick-slider slick-dots-light dots-inner-center cursor-pointer"
              {...sliderSettings}
            >
              {activeSliders.map(data => (
                <div className="box" key={data._id}>
                  <div className="d-flex flex-column">
                    {isMobile ? (
                      <img
                        className="mobile"
                        src={ImageLink(data.slider_mobile_image)}
                        alt="Mobile Banner"
                      />
                    ) : (
                      <img
                        className="desktop"
                        src={ImageLink(data.slider_image)}
                        alt="Home Banner"
                      />
                    )}
                  </div>
                </div>
              ))}
            </Slider>
          </section>
          <Section2 menu={menu} settingData={settingData} />
          <Section3 settingData={settingData} />
          <Section4 settingData={settingData} />
          <Section5 menu={menu} settingData={settingData} />
          <Section7 settingData={settingData} />
          <Section8 settingData={settingData} />
          <Section9 />
        </>
      )}
    </>
  );
};

export default Section1;
