import React, { useState, useMemo } from "react";
import { getBoxImage } from "../services/getBoxImage";
import { ImageLink } from "../constants";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Section3 = ({ settingData }) => {
  const [boxImage, setBoxImage] = useState([]);

  useMemo(async () => {
    try {
      const [boxImageRes] = await Promise.all([getBoxImage()]);
      setBoxImage(boxImageRes.document);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }, []);

  const activeBoxImages = useMemo(
    () => boxImage.filter((item) => item.isActive),
    [boxImage]
  );

  return (
    <>
      {boxImage.length > 0 && (
        <section className="pb-8">
          <div className="container">
            <h1 className="text-center mb-7 fs-24">
              {settingData[0]?.box_title}
            </h1>
            <div className="row">
              {activeBoxImages.map((item, index) =>{ 
                return(
                <div
                  className={`col-lg-${
                    index < 3 ? "4" : index === 4 ? "6" : "3"
                  } mb-5`}
                  key={item._id}
                >
                  <Link to={`/${item.url}`}>
                  <span className="card border-0 hover-shine hover-change-content">
                    <img                     
                      src={ImageLink(item.box_image)}
                      alt="collection"
                      className="card-img"
                    />
                  </span>
                  </Link>
                </div>
              )})}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Section3;
