import React, { useState, useCallback, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getMenuPage } from "../services/getMenuPage";
import { getPageContent } from "../services/getPageContent";
import { ImageLink } from "../constants";
import Loader from "../layout/Loader";
import { toast } from "react-toastify";

const History = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const [contentData, setContentData] = useState([]);
  const [menuPageData, setMenuPageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const menuPageResponse = await getMenuPage(pathname);
      setMenuPageData(menuPageResponse.document);

      if (menuPageResponse?.document?.CMS?._id) {
        const contentResponse = await getPageContent(
          menuPageResponse.document?.CMS?._id
        );
        setContentData(contentResponse.data);
      }
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setIsLoading(false);
    }
  }, [pathname]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {menuPageData?.CMS?.title && (
        <Helmet>
          <title>{`K M Choksi - ${menuPageData?.CMS?.title}`}</title>
        </Helmet>
      )}
      {contentData?.contentId?.length > 0 && (
        <main id="content">
          {menuPageData?.CMS?.background_image ?
            <section
              className="inner-banner"
              style={{
                backgroundImage: `url(${ImageLink(
                  menuPageData?.CMS?.background_image
                )})`,
              }}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="inner-banner-text">
                      <h1>{menuPageData?.CMS?.title}</h1>
                      <p>{menuPageData?.CMS?.subTitle}</p>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <img
                      className="w-100 inner-banner-img"
                      src={ImageLink(menuPageData?.CMS?.main_banner_image)}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </section>
            : <section className="inner-banner" style={{
              backgroundImage: `url(../images/4.jpg)`,
              height: '308px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }} />
          }
          <div className="pt-md-10 pb-md-15">
            <div className="container container-xxl">
              {contentData?.contentId?.map((data, i) => {
                return (
                  <div
                    key={i}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                );
              })}
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default History;
