import React, { useState, useMemo, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ImageLink } from "../constants";
import Slider from "react-slick";
import { getAllCategory } from "../services/getAllCategory";
import { getCategoryProduct } from "../services/getCategoryProduct";
import { Helmet } from "react-helmet";
import Loader from "../layout/Loader";
import { toast } from "react-toastify";

const OurProducts = ({ contact, menu }) => {
  const settings = {
    autoplay: true,
    infinite: true,
    arrows: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const location = useLocation();
  const navigator = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [category, setCategory] = useState([]);
  const [categoryProducts, setCategoryProducts] = useState([]);
  const [animate, setAnimate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pathname = location.pathname;
  const pathname1 = pathname.split("/")[1];

  const filterData = menu?.filter((item) => item.menuUrl === pathname1)

  useMemo(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [categoryRes, categoryProductRes] = await Promise.all([
          getAllCategory(),
          getCategoryProduct(),
        ]);
        setCategory(categoryRes.document);
        setCategoryProducts(categoryProductRes.document);
      } catch (error) {
        toast.error(error.response.data.error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  useMemo(() => {
    const timeout = setTimeout(() => {
      setAnimate(false);
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);

  useMemo(() => {
    setAnimate(true);
  }, []);

  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`K M Choksi - ${filterData[0]?.name}`}</title>
      </Helmet>

      <main id="content">
        <section className="inner-banner">
          <div className="container cursor-pointer box">
            <Slider {...settings} className="slick-slider">
              {category.map((item, index) => (
                item.isActive === true &&
                item.showInHome === true &&
                <div key={index}>
                  <div className={item._id ? "fadeInUp active" : "fadeInUp"}>
                    <Link
                      to={`${location.pathname}/${item.slug}`}
                      className={`d-block inner-box-collection `}
                    >
                      <img
                        src={ImageLink(item.category_box_image)}
                        alt="collection 01"                        
                      />
                      <h4 className="hover-name">{item.name}</h4>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </section>
        <section className="pt-8">
          <div className="container">
            <div className=" mb-4 overflow-hidden" data-animate="fadeInUp">
              {categoryProducts.map((category, categoryIndex) => (
                <div key={categoryIndex}>
                  <div className="d-flex my-3 justify-content-between">
                    <h2>{category.name}</h2>
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        navigator(
                          `${location.pathname}/${category.products[0]?.category?.slug}`,
                          { state: { slug: category.products[0]?.category?.slug, name: category.products[0]?.category?.name } }
                        )
                      }
                    >
                      Show More
                    </button>
                  </div>
                  <div className="row">
                    {category?.products.length ? (
                      category?.products.map((item, index) => (
                        <div
                          key={index}
                          className={`col-6 col-sm-6 col-lg-3 mb-8 ${animate ? "fadeInUp " : ""}`}
                          style={{ animationDelay: `${index * 0.5}s` }}
                        >
                          <div className="card border-0 hover-change-content product cursor-pointer">
                            <div className="card-img-top position-relative overflow-hidden">
                              <div
                                style={{
                                  backgroundImage: `url(${ImageLink(item.mediumproduct_image[0])})`,
                                  transition: "transform 1.5s ease",
                                  transform: hoveredIndex === index ? "scale(1.2)" : "scale(1)",
                                }}
                                className="card-img ratio bg-img-cover-center ratio-1-1"
                                onClick={() => {
                                  navigator(
                                    `${location.pathname}/${category.products[0]?.category?.slug}/${item.slug}`,
                                    { state: { slug: item.slug, name: item.name } }
                                  );
                                }}
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                              />
                              <div className="position-absolute pos-fixed-bottom px-4 px-sm-6 pb-5 d-flex w-100 justify-content-center content-change-horizontal">
                                <Link
                                  target="_blank"
                                  to={`https://wa.me/91${contact[0]?.whatsup_no}?text=${encodeURIComponent(item.name)}`}
                                  data-toggle="tooltip"
                                  title="Whatsapp"
                                  className="d-flex align-items-center justify-content-center text-primary bg-white hover-white bg-hover-primary w-45px h-45px rounded-circle mr-2 border"
                                >
                                  <i className="fab fa-whatsapp" />
                                </Link>
                                <span  onClick={() => {
                                      navigator(
                                        `${location.pathname}/${category.products[0]?.category?.slug}/${item.slug}`,
                                        { state: { slug: item.slug, name: item.name } }
                                      );
                                    }}>
                                  <Link
                                   to="#"
                                    data-toggle="tooltip"
                                    title="Enquire Now"
                                    className="d-flex align-items-center justify-content-center text-primary bg-white hover-white bg-hover-primary w-45px h-45px rounded-circle mr-2 border"
                                  >
                                    <i className="fal fa-envelope-open-text" />
                                  </Link></span>
                              </div>
                            </div>
                            <div
                              className="card-body text-center item-title"
                              onClick={() => {
                                navigator(
                                  `${location.pathname}/${category.products[0]?.category?.slug}/${item.slug}`,
                                  { state: { slug: item.slug, name: item.name } }
                                );
                              }}
                            >
                              <div className="">
                                <p className="letter-spacing-05 d-block font-weight-500 mb-1 hover-name">
                                  {windowWidth > 1024
                                    ? item.name?.length > 26
                                      ? `${item.name.substring(0, 26)}...`
                                      : item.name
                                    : item.name?.length > 10
                                      ? `${item.name.substring(0, 10)}...`
                                      : item.name}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12">
                        <h5>No products found.</h5>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default OurProducts;
