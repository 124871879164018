import React, { useEffect, useState } from "react";
import { getNewsEvent } from "../services/getNewsEvent";
import { Link, useLocation } from "react-router-dom";
import { Animated } from "react-animated-css";
import { Helmet } from "react-helmet";
import { ImageLink } from "../constants";
import Loader from "../layout/Loader";
import { toast } from "react-toastify";

const NewsEvent = ({ menu }) => {
  const [newsEvent, setNewsEvent] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(12);
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const pathname = location.pathname.split("/")[1];

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  useEffect(() => {
    const fetchNewsEvent = async () => {
      try {
        const res = await getNewsEvent();
        setNewsEvent(res.document);
      } catch (error) {
        toast.error(error.response.data.error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNewsEvent();
  }, []);

  const indexOfLastProduct = currentPage * perPage;
  const indexOfFirstProduct = indexOfLastProduct - perPage;
  const currentProducts = newsEvent?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil((newsEvent?.length || 0) / perPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filterData = menu.filter((item) => item.menuUrl === pathname);

  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }

  return (
    <>
      {filterData[0]?.name && (
        <Helmet>
          <title>{`K M Choksi - ${filterData[0]?.name}`}</title>
        </Helmet>
      )}
      <main id="content">
        <section className="pt-10 pb-11 pb-lg-15">
          <div className="container container-xxl">
            <h2 className="fs-sm-30 mb-8 text-center">{filterData[0]?.name}</h2>
            <div className="row">
              {currentProducts?.map((data) =>
                data.isActive === true ? (
                  <Animated
                    key={data._id}
                    animationOut="fadeInUp"
                    animationIn="fadeInUp"
                    isVisible={true}
                    className="col-sm-6 col-lg-3 mb-8"
                  >
                    <div className="card border-0 cursor-pointer">
                      <Link to={`${location.pathname}/${data.slug}`}>
                        <span className="hover-shine card-img-top">
                          <img
                            src={ImageLink(data.event_image)}
                            alt="event_image"
                            className="w-100"
                          />
                        </span>
                      </Link>
                      <div className="card-body px-0 pt-4 pb-0">
                        <p className="card-text fs-12 mb-2 text-muted lh-12 text-uppercase letter-spacing-05 font-weight-500">
                          {data.eventDate
                            ? formatDate(data.eventDate.substring(0, 10))
                            : ""}
                        </p>
                        <p className="card-title mb-0 fs-18">
                        </p>
                      </div>
                    </div>
                  </Animated>
                ) : (
                  ""
                )
              )}
            </div>

            <nav className="pb-11 pb-lg-14 overflow-hidden">
              <ul className="pagination justify-content-center align-items-center mb-0">
                <li
                  className={`page-item fs-12 ${currentPage === 1 ? "disabled" : ""
                    }`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    tabIndex="-1"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="far fa-angle-double-left"></i>
                  </Link>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    className={`page-item ${currentPage === index + 1 ? "active" : ""
                      }`}
                    key={index}
                  >
                    <Link
                      className="page-link"
                      to="#"
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                ))}
                <li
                  className={`page-item fs-12 ${currentPage === totalPages ? "disabled" : ""
                    }`}
                >
                  <Link
                    className="page-link"
                    to="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="far fa-angle-double-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </section>
      </main>
    </>
  );
};

export default NewsEvent;
