import React, { useState, useMemo } from "react";
import { getAllCategory } from "../services/getAllCategory";
import { Link } from "react-router-dom";
import { ImageLink } from "../constants";
import { toast } from "react-toastify";

const Section2 = ({ menu, settingData }) => {
  const [category, setCategory] = useState([]);
  useMemo(async () => {
    try {
      const [categoryRes] = await Promise.all([getAllCategory()]);
      setCategory(categoryRes.document);
    } catch (error) {
      toast.error(error.response.data.error);
    }
  }, []);

  const activeCategories = useMemo(
    () => category.filter((data) => data.isActive),
    [category]
  );
  return (
    <>
      {category.length > 0 && (
        <section className="py-8">
          <div className="container border-bottom pb-8 pb-lg-8">
            <h1 className="text-center mb-5 fs-30">
              {settingData[0]?.collection_title}
            </h1>
            <div className="d-flex flex-wrap client-logo-02 justify-content-center cursor-pointer">
              {activeCategories.map((data) => {
                return (
                  data.isActive === true &&
                  data.showInHome === true && (
                    <Link
                      to={`${menu && menu[2]?.menuUrl}/${data?.slug}`}
                      className="d-block item"
                      key={data._id}
                    >
                      <img
                        src={ImageLink(data.category_box_image)}
                        alt="Collection"
                      />
                      <h4 className="hover-name">{data.name}</h4>
                    </Link>
                  )
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Section2;
