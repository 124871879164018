import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { ImageLink } from '../constants';
import { getNewsEventById } from '../services/getNewsEventById';
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';

const NewsEventDetails = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const pathname1 = pathname.split("/")[1];
  const pathname2 = pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState(true);
  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const [news, setNews] = useState([])

  useEffect(() => {
    const fetchNewsEvent = async () => {
      try {
        const res = await getNewsEventById(pathname2);
        setNews(res.document);
      } catch (error) {
        toast.error('Error fetching news event:', error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchNewsEvent();
  }, [pathname2]);
  
  if (isLoading) {
    return (
      <div className="pre-loader">
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>{news?.title || "K M choksi"}</title>
        <meta name="description" content={news?.description || "K M choksi"} />
        <meta name="Keyword" content="K M choksi" />
      </Helmet>
      <main id="content">
        <section className="pt-2 border-bottom">
          <div className="container">
            <p className="card-text fs-12 mb-2 text-muted lh-12 text-uppercase letter-spacing-05 text-center font-weight-500">
              {news?.eventDate ? formatDate(news?.eventDate?.substring(0, 10)) : ""}
            </p>
            <h1 className="mb-2 fs-40 text-center">{news?.title}</h1>
            <div className="row no-gutters">
              <div className="col-lg-9 mx-auto mb-7 image-container">
                <img
                  src={ImageLink(news?.event_image)}                  
                  alt=""
                />
                {news?.description &&
                  <div
                    className="mt-4"
                    dangerouslySetInnerHTML={{ __html: news?.description }}
                  />}
              </div>
            </div>
          </div>
        </section>
        <section className="pb-5 pt-5">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-sm-12 align-items-center">
                <div className="media align-items-center py-1 justify-content-center">
                  <Link to={`/${pathname1}`} className="fs-14">
                    <i className="fas fa-arrow-left mr-2" /> BACK TO NEWS &amp;
                    EVENTS
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default NewsEventDetails